<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.position.label')"
                label-for="position"
                :state="errors && errors.position ? false : null"
              >
                <b-form-input
                  :id="'position'"
                  v-model="item.position"
                  :state="errors && errors.position ? false : null"
                />
                <b-form-invalid-feedback v-if="errors && errors.position">
                  {{ errors.position[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.phone.label')"
                label-for="phones"
              >
                <b-form-input
                  id="phones"
                  v-model="item.phones"
                  :state="errors && errors.phones ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.phones">
                  {{ errors.phones[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('form.google_map.label')"
                label-for="map"
              >
                <b-form-input
                  id="slug"
                  v-model="item.map"
                  :state="errors && errors.map ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.map">
                  {{ errors.map[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-tabs>
            <b-tab
              v-for="language in languages"
              :key="language.code"
            >
              <template #title>
                <b-img
                  :src="require('@/assets/images/flags/' + language.code + '.svg')"
                  height="16"
                  width="16"
                  class="mr-1"
                />
                <span class="d-none d-sm-inline">{{ language.title }}</span>
              </template>
              <b-row>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.title.label')"
                    :label-for="'title.' + language.code"
                  >
                    <b-form-input
                      :id="'title.' + language.code"
                      v-model="item.title[language.code]"
                      :state="errors && errors['title.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                      {{ errors['title.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.description.label')"
                    :label-for="'description.' + language.code"
                  >
                    <b-form-input
                      :id="'description.' + language.code"
                      v-model="item.description[language.code]"
                      :state="errors && errors['description.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                      {{ errors['description.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.schedule.label')"
                    :label-for="'schedule.' + language.code"
                  >
                    <b-form-input
                      :id="'schedule.' + language.code"
                      v-model="item.schedule[language.code]"
                      :state="errors && errors['schedule.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['schedule.' + language.code]">
                      {{ errors['schedule.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.href.label')"
                    :label-for="'schedule2.' + language.code"
                  >
                    <b-form-input
                      :id="'schedule2.' + language.code"
                      v-model="item.schedule2[language.code]"
                      :state="errors && errors['schedule2.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['schedule2.' + language.code]">
                      {{ errors['schedule2.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'shops-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BButton,
  BRow,
  BCol,
  BMedia,
  BMediaBody,
  BMediaAside,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BFormInvalidFeedback,
  BImg,
  BOverlay, BLink, BFormFile,
} from 'bootstrap-vue'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  directives: {
    Ripple,
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BFormFile,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BImg,
    BFormInvalidFeedback,
    BOverlay,
  },
  data() {
    return {
      item: null,
      languages: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    this.item = await this.transformData()
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async onSubmit() {
      const data = {
        title: this.item.title,
        description: this.item.description,
        schedule: this.item.schedule,
        schedule2: this.item.schedule2,
        map: this.item.map,
        phones: this.item.phones,
        position: this.item.position,
      }

      this.$http.post('/api/admin/shops', data)
        .then(() => {
          router.replace({ name: 'shops-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    transformData() {
      const data = {
        title: {},
        description: {},
        schedule: {},
        schedule2: {},
        map: null,
        phones: null,
        position: 1,
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.description[language.code] = null
        data.schedule[language.code] = null
        data.schedule2[language.code] = null
      })

      return data
    },
  },
}
</script>
